import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentData,
} from "@angular/fire/firestore";
import { AngularFireFunctions } from "@angular/fire/functions";

import { Alignment, Worksheet } from "exceljs";
import * as firebase from "firebase/app";
import { firestore, global, message, xls } from "src/environments/environment";
import { CustomerMin } from "../interface_new/customer";
import { Garage } from "../interface_new/garage";
import {
  CustomerTicket,
  GarageTicket,
  MonthlyTicket,
} from "../interface_new/ticket";
import { DataService } from "./data.service";
import { NotificationService } from "./notification.service";

@Injectable({
  providedIn: "root",
})
export class TicketService {
  ticketCollection: AngularFirestoreCollection;
  archivedTicketCollection: AngularFirestoreCollection;

  constructor(
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
    public notificationService: NotificationService,
    private dataService: DataService
  ) {
    this.ticketCollection = this.afs.collection<MonthlyTicket>(
      firestore.collection.ticket
    );
    this.archivedTicketCollection = this.afs.collection<MonthlyTicket>(
      firestore.collection.ticketArchived
    );
  }

  resetDocument(ticket): Promise<void> {
    var batch = this.afs.firestore.batch();
    batch.delete(
      this.archivedTicketCollection.doc(
        this.createTicketDocumentId(
          ticket.garageId,
          ticket.feeMonth,
          ticket.feeYear
        )
      ).ref
    );
    batch.set(
      this.ticketCollection.doc(
        this.createTicketDocumentId(
          ticket.garageId,
          ticket.feeMonth,
          ticket.feeYear
        )
      ).ref,
      ticket
    );
    return batch.commit();
  }

  paymentTypeLabel(paymentType: string): string {
    if (paymentType == global.paymentType.cash.value)
      return global.paymentType.cash.label;
    else if (paymentType == global.paymentType.pos.value)
      return global.paymentType.pos.label;
    else if (paymentType == global.paymentType.check.value)
      return global.paymentType.check.label;
    else if (paymentType == global.paymentType.transaction.value)
      return global.paymentType.transaction.label;
    else return "";
  }

  paymentTypeIcon(paymentType: string): string {
    if (paymentType == global.paymentType.cash.value)
      return global.paymentType.cash.icon;
    else if (paymentType == global.paymentType.pos.value)
      return global.paymentType.pos.icon;
    else if (paymentType == global.paymentType.check.value)
      return global.paymentType.check.icon;
    else if (paymentType == global.paymentType.transaction.value)
      return global.paymentType.transaction.icon;
    else return "";
  }

  getMonthString(month: number) {
    return global.month[month] !== undefined ? global.month[month].label : "";
  }
  getMonthStringShort(month: number) {
    return global.month[month] !== undefined ? global.month[month].short : "";
  }

  buildGarageExcelHeader(
    worksheet: Worksheet,
    startIndex: number,
    garage: Garage
  ) {
    let titleRow = worksheet.getCell("E" + startIndex);
    titleRow.value = garage.name;
    titleRow.font = xls.titleFont;
    titleRow.alignment = xls.titleAlignment as Partial<Alignment>;
    startIndex += 1;
    if (garage.address !== undefined && garage.address != null) {
      let subTitleRow = worksheet.getCell("E" + startIndex);
      subTitleRow.value = garage.address.formatted_address;
      subTitleRow.font = xls.subTitleFont;
      subTitleRow.alignment = xls.titleAlignment as Partial<Alignment>;
    }
    startIndex += 1;
    let contactRow = worksheet.getCell("E" + startIndex);
    contactRow.value = "";
    if (
      garage.mobilePhoneNumber != null &&
      garage.mobilePhoneNumber !== undefined &&
      garage.mobilePhoneNumber != ""
    ) {
      contactRow.value += "Cellulare : " + garage.mobilePhoneNumber;
    }
    if (
      garage.phoneNumber != null &&
      garage.phoneNumber !== undefined &&
      garage.phoneNumber != ""
    ) {
      if (contactRow.value != "") contactRow.value += " - ";
      contactRow.value += "Telefono : " + garage.mobilePhoneNumber;
    }
    contactRow.font = xls.contactFont;
    contactRow.alignment = xls.titleAlignment as Partial<Alignment>;
    startIndex += 1;
    let pIvaRow = worksheet.getCell("E" + startIndex);
    pIvaRow.value = "";
    if (
      garage.email != null &&
      garage.email !== undefined &&
      garage.email != ""
    ) {
      pIvaRow.value += "Email : " + garage.email;
    }
    if (
      garage.fiscalCode != null &&
      garage.fiscalCode !== undefined &&
      garage.fiscalCode != ""
    ) {
      if (pIvaRow.value != "") pIvaRow.value += " - ";
      pIvaRow.value += "CF/PIVA : " + garage.fiscalCode;
    }
    pIvaRow.font = xls.contactFont;
    pIvaRow.alignment = xls.titleAlignment as Partial<Alignment>;
    return startIndex;
  }

  functionTicketData(garageTicket: GarageTicket, garage: Garage): any {
    var garageTOSend = {
      garageId: garage.garageId,
      address: garage?.address?.formatted_address,
      description: garage?.description,
      email: garage?.email,
      fiscalCode: garage.fiscalCode,
      logo: garage.logo,
      mobilePhoneNumber: garage.mobilePhoneNumber,
      phoneNumber: garage.phoneNumber,
      name: garage.name,
    };
    return {
      ticketItem: garageTicket,
      garage: garageTOSend,
      customer: garageTicket.customer,
    };
  }

  sendTicketToCustomer(garageTicket: GarageTicket, garage: Garage): void {
    var data = this.functionTicketData(garageTicket, garage);
    this.aff
      .httpsCallable(firestore.function.sendTicketToCustomer)(data)
      .subscribe(
        async (res) => {
          this.dataService.isDownLoading(false);
          this.notificationService.okNotification(
            message.ticket.sendTicketSuccess
          );
        },
        (err) => {
          this.dataService.isDownLoading(false);
          this.notificationService.koNotification(
            message.ticket.sendTicketError
          );
        }
      );
  }

  downloadTicket(garageTicket: GarageTicket, garage: Garage): void {
    var data = this.functionTicketData(garageTicket, garage);
    this.aff
      .httpsCallable(firestore.function.downloadTicket)(data)
      .subscribe(
        async (res) => {
          this.dataService.isDownLoading(false);
          if (res && res[0]) {
            await window.open(res[0]);
          }
        },
        (err) => {
          this.dataService.isDownLoading(false);

          this.notificationService.koNotification(
            message.ticket.ticketDownloadError
          );
        }
      );
  }

  getTaxAmount(total: number, vat: number): number {
    var netAmount = (total * 100) / (vat + 100);
    return +(total - netAmount).toFixed(2);
  }

  createGarageCustomerMonthlyTicket(
    garage: Garage,
    selectedMonth: number,
    selectedYear: number
  ): GarageTicket[] {
    var ticketList: GarageTicket[] = [];
    var lastUpdateDate: firebase.firestore.Timestamp =
      firebase.firestore.Timestamp.fromDate(new Date());
    if (garage.customerList != null || garage.customerList !== undefined) {
      garage.customerList.forEach((customer) => {
        var customerMin: CustomerMin = {
          customerId: customer.customerId,
          name: customer.name,
          surname: customer.surname,
          email: customer.email,
          displayName: customer.displayName,
          customerCode: customer.customerCode,
          fiscalCode: customer.fiscalCode,
          lastUpdate: customer.lastUpdate,
        };
        var vehicleList = garage.vehicleList.filter(
          (obj) => obj.customerId == customer.customerId
        );
        var totalprice = 0;
        var totalTax = 0;
        vehicleList.forEach((obj) => (totalprice += obj.price.price));
        vehicleList.forEach(
          (obj) =>
            (totalTax += this.getTaxAmount(obj.price.price, obj.price.vat))
        );

        var customerTicket: CustomerTicket = {
          total: totalprice,
          taxAmount: +totalTax.toFixed(2),
          servicePrice: 0,
          serviceList: [],
          isPaid: false,
          paymentDate: null,
          paymentType: "",
          ticketId: this.createCustomerTicketId(
            customer.customerId,
            selectedMonth,
            selectedYear,
            false
          ),
          isServiceTicket: false,
          feeMonth: selectedMonth,
          feeYear: selectedYear,
          lastUpdate: lastUpdateDate,
        };
        var garageTicket: GarageTicket = {
          customer: customerMin,
          vehicleList: vehicleList,
          ticket: customerTicket,
        };

        ticketList.push(garageTicket);
      });
    }
    return ticketList;
  }

  addCustomerTicketGarageCustomerMonthlyTicketList(
    garage: Garage,
    selectedMonth: number,
    selectedYear: number,
    customerCode: string,
    ticketList: GarageTicket[]
  ): GarageTicket[] {
    var lastUpdateDate: firebase.firestore.Timestamp =
      firebase.firestore.Timestamp.fromDate(new Date());
    if (garage.customerList != null || garage.customerList !== undefined) {
      var customer = garage.customerList.find(
        (customer) => customer.customerCode == customerCode
      );
      var customerMin: CustomerMin = {
        customerId: customer.customerId,
        name: customer.name,
        surname: customer.surname,
        email: customer.email,
        displayName: customer.displayName,
        customerCode: customer.customerCode,
        fiscalCode: customer.fiscalCode,
        lastUpdate: customer.lastUpdate,
      };

      var vehicleList = garage.vehicleList.filter(
        (obj) => obj.customerId == customerMin.customerId
      );
      var totalprice = 0;
      var totalTax = 0;
      vehicleList.forEach((obj) => (totalprice += obj.price.price));
      vehicleList.forEach(
        (obj) => (totalTax += this.getTaxAmount(obj.price.price, obj.price.vat))
      );

      var customerTicket: CustomerTicket = {
        total: totalprice,
        taxAmount: +totalTax.toFixed(2),
        servicePrice: 0,
        serviceList: [],
        isPaid: false,
        paymentDate: null,
        paymentType: "",
        ticketId: this.createCustomerTicketId(
          customerMin.customerId,
          selectedMonth,
          selectedYear,
          false
        ),
        isServiceTicket: false,
        feeMonth: selectedMonth,
        feeYear: selectedYear,
        lastUpdate: lastUpdateDate,
      };
      var garageTicket: GarageTicket = {
        customer: customerMin,
        vehicleList: vehicleList,
        ticket: customerTicket,
      };

      ticketList.push(garageTicket);
    }
    return ticketList;
  }

  createEmptyMonthlyTicket(): MonthlyTicket {
    return {
      garageId: "",
      feeMonth: 0,
      feeYear: 0,
      isClosed: false,
      ticketList: [],
      adminList: [],
      employeeList: [],
    };
  }

  createCustomerTicketId(
    customerId: string,
    month: number,
    year: number,
    exist: boolean
  ): string {
    var monthString = month < 10 ? "0" + month : month.toString();

    return exist
      ? customerId +
          "-" +
          monthString +
          "-" +
          year.toString() +
          "-" +
          this.afs.createId()
      : customerId + "-" + monthString + "-" + year.toString();
  }

  createTicketDocumentId(
    garageId: string,
    month: number,
    year: number
  ): string {
    var monthString = month < 10 ? "0" + month : month.toString();
    return garageId + "-" + monthString + "-" + year.toString();
  }

  getMonthYearTicketDocument(
    garageId: string,
    month: number,
    year: number
  ): AngularFirestoreDocument {
    return this.ticketCollection.doc(garageId + "-" + month + "-" + year);
  }

  getMonthYearTicketDocumentList(garageId: string[]): DocumentData {
    return this.ticketCollection.ref.where("garageId", "in", garageId);
  }

  createTicketDocumentWithTicket(
    garage: Garage,
    month: number,
    year: number,
    garageTicket: GarageTicket[]
  ): Promise<void> {
    return this.ticketCollection
      .doc(this.createTicketDocumentId(garage.garageId, month, year))
      .set({
        garageId: garage.garageId,
        feeMonth: month,
        feeYear: year,
        isClosed: false,
        ticketList: garageTicket,
        adminList: garage.adminList,
        employeeList: garage.employeeList,
      });
  }

  updateMonthlyTicketTicketList(
    garageId: string,
    month: number,
    year: number,
    ticketList: GarageTicket[]
  ): Promise<void> {
    var documentId = this.createTicketDocumentId(garageId, month, year);
    return this.ticketCollection
      .doc(documentId)
      .update({ ticketList: ticketList });
  }

  openCloseMonthlyTicketTicketList(
    garageId: string,
    month: number,
    year: number,
    isClosed: boolean
  ): Promise<void> {
    var documentId = this.createTicketDocumentId(garageId, month, year);
    return this.ticketCollection.doc(documentId).update({ isClosed: isClosed });
  }

  sortGarageTicketYearMonthDesc(a: GarageTicket, b: GarageTicket): number {
    if (
      (!Number.isNaN(Number(a.ticket.feeYear))
        ? Number(a.ticket.feeYear)
        : a.ticket.feeYear) <
      (!Number.isNaN(Number(b.ticket.feeYear))
        ? Number(b.ticket.feeYear)
        : b.ticket.feeYear)
    ) {
      return 1;
    } else if (
      (!Number.isNaN(Number(a.ticket.feeYear))
        ? Number(a.ticket.feeYear)
        : a.ticket.feeYear) >
      (!Number.isNaN(Number(b.ticket.feeYear))
        ? Number(b.ticket.feeYear)
        : b.ticket.feeYear)
    ) {
      return -1;
    } else {
      if (
        (!Number.isNaN(Number(a.ticket.feeMonth))
          ? Number(a.ticket.feeMonth)
          : a.ticket.feeMonth) <
        (!Number.isNaN(Number(b.ticket.feeMonth))
          ? Number(b.ticket.feeMonth)
          : b.ticket.feeMonth)
      ) {
        return 1;
      } else if (
        (!Number.isNaN(Number(a.ticket.feeMonth))
          ? Number(a.ticket.feeMonth)
          : a.ticket.feeMonth) >
        (!Number.isNaN(Number(b.ticket.feeMonth))
          ? Number(b.ticket.feeMonth)
          : b.ticket.feeMonth)
      ) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  sortMonthlyTicketYearMonthAsc(a: MonthlyTicket, b: MonthlyTicket): number {
    if (
      (!Number.isNaN(Number(a.feeYear)) ? Number(a.feeYear) : a.feeYear) >
      (!Number.isNaN(Number(b.feeYear)) ? Number(b.feeYear) : b.feeYear)
    ) {
      return 1;
    } else if (
      (!Number.isNaN(Number(a.feeYear)) ? Number(a.feeYear) : a.feeYear) <
      (!Number.isNaN(Number(b.feeYear)) ? Number(b.feeYear) : b.feeYear)
    ) {
      return -1;
    } else {
      if (
        (!Number.isNaN(Number(a.feeMonth)) ? Number(a.feeMonth) : a.feeMonth) >
        (!Number.isNaN(Number(b.feeMonth)) ? Number(b.feeMonth) : b.feeMonth)
      ) {
        return 1;
      } else if (
        (!Number.isNaN(Number(a.feeMonth)) ? Number(a.feeMonth) : a.feeMonth) <
        (!Number.isNaN(Number(b.feeMonth)) ? Number(b.feeMonth) : b.feeMonth)
      ) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  sortGarageTicketCustomerCodeAsc(a: GarageTicket, b: GarageTicket): number {
    if (
      (!Number.isNaN(Number(a.customer.customerCode))
        ? Number(a.customer.customerCode)
        : a.customer.customerCode) <
      (!Number.isNaN(Number(b.customer.customerCode))
        ? Number(b.customer.customerCode)
        : b.customer.customerCode)
    ) {
      return -1;
    } else if (
      (!Number.isNaN(Number(a.customer.customerCode))
        ? Number(a.customer.customerCode)
        : a.customer.customerCode) >
      (!Number.isNaN(Number(b.customer.customerCode))
        ? Number(b.customer.customerCode)
        : b.customer.customerCode)
    ) {
      return 1;
    } else {
      return 0;
    }
  }

  getAllArchivedTicketByGarage(garageId: string): DocumentData {
    return this.archivedTicketCollection.ref.where("garageId", "==", garageId);
  }
}
